<template>

  <div id="app">
    <div class="alert alert-warning text-center" v-if="!online">You went offline. Please check your internet connection and then refresh the page.</div>

    <!-- -->
    <SetupChannel />

    <!-- LINKS -->
    <nav class="font-weight-bold p-4 text-center">
      <a href="https://seeburg1000.com/" target="_blank">Main Site</a>
      <a target="_blank" class="ml-5" href="https://seeburg1000.bigcartel.com/">Store</a>
    </nav>


    <div class="container-fluid mb-5">
      <div class="row justify-content-center text-center">
        <!-- VIDEO STREAM -->
        <div class="iframe-box col-md-6 p-2 pl-md-5">
          <iframe class="video-iframe" src="https://iframe.dacast.com/live/f72b0dab-fc4e-62a6-9fbc-30d40440d106/bdc8de49-fee3-7465-174c-0b6721ebc9ea" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
          <p style="margin-top: 21px;"><b><a target="_blank" href="https://seeburgmusic.com/">Seeburg Music</a></b></p>
        </div>
        <!-- VESTABOARD -->
        <div class="iframe-box col-md-6 p-2">
          <iframe class="vesta-iframe" src="https://simulator.vestaboard.com/?boardId=f8b7313f-7a9e-4122-abdf-1c3c49d6f327" scrolling="no" style="border: none"></iframe>
          <p><b><a href="https://vestaboard.com/">Vestaboard</a></b></p>
        </div>
      </div>
    </div>


    <!-- Currently Playing -->
    <CurrentlyPlaying/>
  </div>

</template>

<script>

  import CurrentlyPlaying from './components/CurrentlyPlaying.vue'
  import { SetupChannel } from '@vestaboard/installables'

  export default {
    name: 'App',
    computed: {
      online: function(){
        return navigator.onLine;
      }
    },
    components: {
      CurrentlyPlaying,
      SetupChannel
    },
    created: function(){
      document.title = "Seeburg Music Livestream"
    }
  }

</script>

<style>
.video-iframe{
  width: 100%;
  height: 380.7px;
  border: none;
}

.vesta-iframe{
  width: 710px;
  height: 404.7px;
}

@media only screen and (max-width: 750px) {
  .video-iframe, .vesta-iframe{
    max-width: 90%;
    height: 250px;
  }
}

</style>
