<template>
<div class="align-center currently-playing text-center">

  <div class="show-track" v-if="track.status">
    <!-- RECORD && CURRENT TRANK -->
    <p v-show="recordString && record && record.id && trackString && currentTrack.id"><b>Currently Playing</b>: <a target="_blank" :href="'https://seeburg.herokuapp.com/records/' + record.id">{{ recordString }}</a> | <a
        :href="'https://seeburg.herokuapp.com/tracks/' + currentTrack.id" target="_blank">{{ trackString }}</a></p>

    <!-- Tags -->
    <p v-if="this.currentTrack.tags && this.currentTrack.tags.length > 0"><b>Tags:</b> <span>{{ this.currentTrack.tags }}</span></p>
    <p v-if="this.currentTrack.artist && this.currentTrack.artist.length > 1"><b>Composer:</b> <span>{{ this.currentTrack.artist }}</span></p>

    <!-- NEXT TRACK -->
    <small v-if="nextTrack && nextTrack.status && nextTrack.title && nextTrack.id"><b>Next Up:</b> <a target="_blank" :href="'https://seeburg.herokuapp.com/tracks/' + nextTrack.id">{{ nextTrack.title }}</a><span v-show="nextUpCountdown > 0"> in
        {{ duration }}</span></small>

    <!-- RECORD CHANGE AHEAD -->
    <small v-if="!nextTrack || !nextTrack.status"><span>Record Change Ahead</span> <span v-if="nextUpCountdown > 0"> in
        {{ duration }}</span></small>

    <!-- SPOTIFY -->
    <div v-if="spotifyStatus && spotifyTracks.length > 0" class="container">
      <h3 class="text-center mt-3">Related Tracks on Spotify</h3>
      <div class="row justify-content-center">
        <div v-for="s in spotifyTracks" :key="s.id" class="m-3 col-md-3">
          <iframe :src="'https://open.spotify.com/embed/track/' + s.sid" width="250" height="100" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="show-stand-by" v-if="!track.status">
    <h3 class="text-center">STAND BY</h3>
  </div>
</div>
</template>

<script>
export default {
  name: 'CurrentlyPlaying',
  computed: {
    secondsRemainder: function() {
      if ((this.currentTrack && this.currentTrack.seconds_left % 60) < 10) {
        return "0" + (this.currentTrack.seconds_left % 60)
      } else if (this.currentTrack && this.currentTrack.seconds_left) {
        return (this.currentTrack.seconds_left % 60);
      } else {
        return null
      }
    },
    duration: function() {
      if (this.currentTrack && this.currentTrack.seconds_left) {
        return parseInt(this.currentTrack.seconds_left / 60) + ":" + this.secondsRemainder;
      } else {
        return null
      }
    },
    nextUpCountdown: function() {
      if (this.currentTrack) {
        return this.currentTrack.seconds_left > 0
      } else {
        return null
      }

    },
    spotifyStatus: function() {
      return this.track.spotify_status
    },
    spotifyTracks: function() {
      return this.track.spotify
    },
    nextTrack: function() {
      return this.track.next_track
    },
    currentTrack: function() {
      return this.track.current_track
    },
    record: function() {
      return this.track.record
    },
    trackString: function() {
      if (this.track.current_track) {
        return (
          this.track.current_track.title +
          ' (' +
          this.track.current_track.side +
          '-' +
          this.track.current_track.position +
          ')'
        )
      } else {
        return null
      }

    },
    recordString: function() {
      if (this.track.record) {
        return this.track.record.number + ' (' + this.track.record.date + ' - ' + this.track.record.library + ')'
      } else {
        return null
      }
    }
  },
  data: function() {
    return {
      url: 'https://seeburg.herokuapp.com/get_latest_stream',
      track: {
        status: false
      }
    }
  },
  props: {},
  methods: {
    countdownTimer: function() {
      if (this.currentTrack && this.currentTrack.seconds_left > 0) {
        this.currentTrack.seconds_left -= 1
      }
      setTimeout(() => {
        this.countdownTimer();
      }, 1000)
    },
    getLatestStream: async function() {
      if (this.url) {
        const response = await fetch(this.url)
        const d = await response.json()
        if (response.status == 200) {
          this.track = d
        }
        setTimeout(() => {
          this.getLatestStream()
        }, 5000)
      }
    }
  },
  async beforeMount() {
    await this.getLatestStream();
    this.countdownTimer();
  }
}
</script>

<style>
</style>
